.footer {
    display: flex;
    padding: 1.5rem 1.5rem;
    line-height: 1.5rem;
    text-align: center;
    background: #FF5733;
}

.footer-bottom {
    display: flex;
    padding: 10px 25px;
    line-height: 1.5rem;
    text-align: center;
    background: #FF5733;
    border-bottom-left-radius: 25px;
    color: white;
}

.footer a {
    color: white;
}

.footer a:hover {
    color: whitesmoke;
    text-decoration: none;
}

.footer-nav-anchors {
    color: white;
    padding: 20px 20px;
    font-size: 20px;
    text-decoration: none;
}

.footer-all-icons {
    padding: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.footer-icon-circle {
    color: #FF5733;
    border-radius: 100px;
    width: 50px;
    height: 50px;
    background: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0 15px;
}

.footer-icon {
    font-size: 26px;
}

.footer-motto {
    padding: 25px;
    color: lightgrey;
    font-size: 14px;
}

@media only screen and (max-width: 600px) {
    .footer-nav-anchors {
        font-size: 12px;
    }
    .footer-nav-anchors {
        padding: 5px;
    }
    .footer-all-icons {
        padding: 20px 0;
    }
}