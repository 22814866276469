.carousel-body {
    background: #0d0d11;

    background-size: cover;
    -ms-background-size: cover;
    -o-background-size: cover;
    -moz-background-size: cover;
    -webkit-background-size: cover;
    height: 95vh;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    /*margin-top: -75px;*/

}

.carousel-body:before {
    content: ' ';
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 101%;
    z-index: 1;
    opacity: 0.65;
    background: url("../../images/kandm.png") no-repeat 50% 0;
    -ms-background-size: cover;
    -o-background-size: cover;
    -moz-background-size: cover;
    -webkit-background-size: cover;
    background-size: cover;
}

.carousel-body h1 {
     /*padding: 50px;*/
     text-align: center;
     z-index: 2;
     position: relative;
     color: #fff;
     font-size: 60px;
 }

.carousel-body p {
    /*padding: 50px;*/
    text-align: center;
    z-index: 2;
    position: relative;
    color: #fff;
    padding-top: 5px;
    font-size: 20px;
}

.Phone_home {
    text-align: center;
}

@media only screen and (max-width: 600px) {
    .carousel-body h1 {
        font-size: 32px;
        color: #FF5733;
        /*opacity: 1;*/
    }
}
