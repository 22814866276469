/*.navbar.transparent.navbar-inverse .navbar-inner {*/
    /*border-width: 0px;*/
    /*-webkit-box-shadow: 0px 0px;*/
    /*box-shadow: 0px 0px;*/
    /*background-color: rgba(0,0,0,0.0);*/
    /*background-image: -webkit-gradient(linear, 50.00% 0.00%, 50.00% 100.00%, color-stop( 0% , rgba(0,0,0,0.00)),color-stop( 100% , rgba(0,0,0,0.00)));*/
    /*background-image: -webkit-linear-gradient(270deg,rgba(0,0,0,0.00) 0%,rgba(0,0,0,0.00) 100%);*/
    /*background-image: linear-gradient(180deg,rgba(0,0,0,0.00) 0%,rgba(0,0,0,0.00) 100%);*/
/*}*/

.transparent {
    background: rgba(255, 255, 255, 0.65);
    /*height: 75px;*/
    box-shadow: 0px 0px 10px #ffffff;
}

.navbar {
    z-index: 10000;
    /*border-bottom-left-radius: 10px;*/
    /*border-bottom-right-radius: 10px;*/
}

.flex-navbar {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
}

.navbar-brand {
    color: #FF5733;
    font-weight: bold;
}

.nav-link {
    color: #FF5733;
    font-weight: bold;
}

@media only screen and (max-width: 600px) {
    .nav-link {
        font-size: 14px;
    }
}

@media only screen and (max-width: 380px) {
    .nav-link {
        font-size: 12px;
    }
}