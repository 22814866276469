.Faq-title {
    text-align: left;
    background: whitesmoke;
    padding: 10px;
    margin: 5px 0;
    cursor: pointer;
    border-radius: 5px;
    box-shadow: 2px 2px 5px gray;
}

.Faq-title-text {
    color: #FF5733;
    font-weight: bold;
}