.All-cards {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.Home-card-title {
    color: #FF5733;
    font-weight: bolder;
}

.All-homes-area {
    /*padding-top: 50px;*/
    padding-bottom: 150px;
    background: rgba(255, 87, 51, 0.15);
}

.All-homes-header {
    padding: 75px 0;
}

.Home-card {
    box-shadow: 2px 2px 10px gray;
    /*height: 650px;*/
}

.Home-card-img:hover {
    cursor: pointer;
    background: white;
    height: 102%;
    width: 102%;
}

.Home-card-overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    opacity: 0;
    transition: .5s ease;
    background-color: #FF5733;
}

.Home-card:hover .Home-card-overlay {
    opacity: 1;
    cursor: pointer;
}

.Home-card-text {
    color: white;
    font-size: 20px;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    text-align: center;
}